import cn from 'classnames'
import { ReadMore } from '../read-more/read-more'
import { Synonyms } from '../synonyms/synonyms'
import { LocationIcon, ClockIcon, VideoCameraIcon } from '@lego/ui/icons'
import { Button } from '@lego/ui/button'
import { AEMImage } from '@abcam-web/shared/ui/content'
import { ContentHeadSize } from './content-head.types'
import type { ContentHeadProps } from './content-head.types'
import ConditionalWrap from 'conditional-wrap'
import {
  useBreakpointListener,
  breakpointsAsNumbers,
} from '@lego/hooks/breakpoint-listener'

const EmptySection = () => {
  return (
    <div className="flex basis-[12.5%] grow-0 lgd:basis-[6.25%] smd:basis-[5.25%]"></div>
  )
}

export function ContentHead({
  title,
  synonyms,
  description,
  imgUrl,
  type = ContentHeadSize.large,
  eventHighLights,
  CTAButton,
  variant,
  breadcrumbSlot = null,
}: ContentHeadProps): JSX.Element {
  const isLargeLayout = useBreakpointListener(breakpointsAsNumbers.lgu, '>')

  const backgroundCondition = !!imgUrl && isLargeLayout

  return (
    <ConditionalWrap
      condition={backgroundCondition}
      wrap={(children) => (
        <AEMImage
          isBackgroundImage
          backgroundPosition="center"
          backgroundSize="cover"
          wrapperClasses=""
          src={imgUrl ? imgUrl : ''}
        >
          {children}
        </AEMImage>
      )}
    >
      <div className={cn('border-b', !backgroundCondition && 'bg-white')}>
        <div className="flex m-auto max-w-7xl justify-items-start mdu:justify-items-center lgu:justify-items-start">
          <EmptySection />
          <div
            className={cn(
              'flex flex-col justify-center font-body text-black-0 my-12 basis-[53.5%] text-body-medium leading-[1.4375rem] smd:basis-[75%] mdd:basis-[59.75%] lgd:basis-[6.25%]',
              !backgroundCondition && 'grow',
              backgroundCondition &&
                'bg-white w-2/3 flex-grow-0 p-layout-4 border-[#E2E2E2] rounded-2xl'
            )}
          >
            {breadcrumbSlot}
            <h1
              className="pb-5 my-5 mb-0 font-semibold tracking-normal text-black-0 font-header text-[3.5rem] leading-[3.5rem] smd:text-[2rem] smd:leading-[2rem] mdd:text-[2.5rem] mdd:leading-[2.5rem]"
              data-testid="content-head-title"
            >
              {title}
            </h1>
            <div className="w-1/6 mb-5 border-t-4 border-[#ff7223]"></div>
            {synonyms && (
              <div className="pb-5 text-xs text-grey-15">
                <Synonyms synonyms={synonyms} />
              </div>
            )}
            {description && (
              <div data-testid="content-head-description">
                <ReadMore fullWord symbolsCount={200} text={description} />
              </div>
            )}
            {variant ? (
              <div className="flex flex-shrink">
                <p>
                  <span className="block mb-2 shadow-[inset 0 -0.25rem #17c67e, inset #17c67e] h-[5px]"></span>
                  A variant of{' '}
                  <a
                    className="font-semibold underline text-[1.2rem] leading-[1.6875rem]"
                    data-testid="content-head-variant"
                    href={variant.url}
                  >
                    {variant.text}
                  </a>
                </p>
              </div>
            ) : null}
            {eventHighLights ? (
              <div
                className="flex flex-wrap mb-6"
                data-testid="content-head-event-highlights"
              >
                <div className="flex items-center mr-4">
                  <LocationIcon className="mr-1" />
                  <p className="font-semibold text-black-0 font-body leading-[27px]">
                    {eventHighLights.location}
                  </p>
                </div>
                <div className="flex items-center mr-4">
                  <VideoCameraIcon className="mr-1" />
                  <p className="font-semibold text-black-0 font-body leading-[27px]">
                    Virtual streaming{' '}
                    {eventHighLights.vStreaming ? 'available' : 'unavailable'}
                  </p>
                </div>
                <div className="flex items-center mr-4">
                  <ClockIcon className="mr-1" fill="#6C7979" />
                  <p className="font-semibold text-black-0 font-body leading-[27px]">
                    {eventHighLights.date}
                  </p>
                </div>
              </div>
            ) : null}
            {CTAButton ? (
              <div
                className="flex mb-20 mdd:mb-10"
                data-testid="content-head-cta-button"
              >
                <Button onClick={() => window.open(CTAButton.link)}>
                  {CTAButton.text}
                </Button>
              </div>
            ) : null}
          </div>
          <EmptySection />
        </div>
      </div>
    </ConditionalWrap>
  )
}
