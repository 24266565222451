import React from 'react'
import styles from './synonyms.module.css'

export type SynonymPropTypes = {
  synonyms: string
  dataCy?: string
}

export function Synonyms({ synonyms, dataCy }: SynonymPropTypes): JSX.Element {
  return (
    <p className={styles.synonyms} data-cy={dataCy} data-testid="synonyms">
      {synonyms}
    </p>
  )
}
