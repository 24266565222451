import React from 'react'
import type { FC } from 'react'
import type { ReadMoreProps } from './read-more.types'
import { useIntl } from 'react-intl'

export const ReadMore: FC<ReadMoreProps> = ({
  symbolsCount,
  text,
  fullWord,
}) => {
  const [showAll, setShowAll] = React.useState<boolean>(false)
  const { formatMessage } = useIntl()

  function calculateVisibleText(): string {
    if (fullWord) {
      for (let i = symbolsCount; 0 < i; i--) {
        // check if this character is whitespace character like space, tab, return etc.
        if (text[i].match(/\s/)) {
          return text.slice(0, i)
        }
      }
    }
    return text.slice(0, symbolsCount)
  }

  if (text.length <= symbolsCount) {
    return <div>{text}</div>
  }

  return (
    <div className="mb-6 leading-normal text-black-0">
      <p className="pb-2">{showAll ? text : calculateVisibleText()} </p>
      <a
        className="underline cursor-pointer text-buttonPrimary"
        data-testid="read-trigger"
        onClick={() => setShowAll(!showAll)}
      >
        {showAll
          ? formatMessage({ id: 'common.summaryExpansion.showLess' })
          : formatMessage({ id: 'common.summaryExpansion.showMore' })}
      </a>
    </div>
  )
}
