import type { LandingPageTemplateProps } from './utilities/landing-page-template.type'
import { ContentHead } from '@abcam-web/shared/ui/content'
import Head from 'next/head'
import type { FC } from 'react'
import { PageSeo } from '@abcam-web/shared/utilities/seo'
import { useEffect, useState } from 'react'
import dynamic from 'next/dynamic'

const RecentlyViewed = dynamic(
  () => import('@abcam-web/shared/pdp/ui/recently-viewed/recently-viewed'),
  { ssr: false }
)

const LandingPageTemplate: FC<LandingPageTemplateProps> = ({
  children,
  description,
  hideHeader,
  seo,
  title,
  canonicalUrl,
  hreflangs,
  breadcrumbSlot,
  imgUrl,
  locale,
  nextSeoProps,
}) => {
  const [recentlyViewed, setRecentlyViewed] = useState([])

  useEffect(() => {
    function getRecentlyViewed() {
      return typeof window === 'undefined'
        ? []
        : setRecentlyViewed(
            JSON.parse(localStorage?.getItem('recentlyViewed') || '[]').slice(
              0,
              8
            )
          )
    }

    getRecentlyViewed()
    window.addEventListener('storage', getRecentlyViewed)

    return () => {
      window.removeEventListener('storage', getRecentlyViewed)
    }
  }, [])

  return (
    <>
      <Head>
        <title>{`${seo.metaTitle} | Abcam`}</title>
        <meta content={seo.metaTitle} name="title" property="og:title" />
        {seo.metaDescription && (
          <meta
            content={seo.metaDescription}
            key="meta description"
            name="description"
            property="og:description"
          />
        )}
        {seo.keywords && (
          <meta content={seo.keywords} key="keywords" name="keywords" />
        )}
      </Head>
      <PageSeo
        {...{
          hreflangs,
          canonicalUrl,
          ...nextSeoProps,
        }}
      />
      {!hideHeader && title && (
        <ContentHead
          title={title}
          description={description}
          breadcrumbSlot={breadcrumbSlot}
          imgUrl={imgUrl}
        />
      )}
      {children}
      {recentlyViewed.length ? (
        <RecentlyViewed recentlyViewedProducts={recentlyViewed} />
      ) : null}
    </>
  )
}

export { LandingPageTemplate }
