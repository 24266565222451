import { ReactNode } from 'react'

export enum ContentHeadSize {
  large = 'LARGE',
  standard = 'STANDARD',
}

export type ContentHeadProps = {
  breadcrumbSlot?: ReactNode
  title: string
  synonyms?: string
  description?: string | null
  imgUrl?: string | null
  type?: ContentHeadSize
  eventHighLights?: {
    location: string
    vStreaming: boolean
    date: string
  }
  variant?: {
    text: string
    url: string
  }
  CTAButton?: {
    text: string
    link: string
  }
}
